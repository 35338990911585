import React from 'react'
import Layout from 'layouts/en'
import { graphql } from 'gatsby'
import SEO from 'components/EN/Seo'
import Banner from 'components/EN/Banner'
import Compare from 'components/Compare'
import InternationalTitle from 'components/InternationalTitle'
import DisplayCard from 'components/EN/DisplayCard'
import * as styles from './index.module.less'

const googleAnalytics = (props) => {
  const { data } = props
  const { googleAnalyticsYaml } = data
  const { title, description, keywords, saTitle, banner, section1, section2 } = googleAnalyticsYaml
  const seo = { title, description, keywords, saTitle, lang: 'en' }
  return (
    <Layout>
      <SEO {...seo} />
      <main className={styles.root}>
        <Banner {...banner} />
        <section className={styles.title}>
          <InternationalTitle title={section1.title} />
          <div className={styles.compareCom}>
            <Compare
              sensorsDataConfig={section1.sensorsDataConfig}
              compareCompany={section1.compareCompany}
              compareContent={section1.compareContent}
            />
          </div>
        </section>
        <DisplayCard className="lg:pb-[100px] pb-[60px]" dataSource={section2} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    googleAnalyticsYaml {
      title
      description
      keywords
      saTitle
      banner {
        title
        desc
        btn_text
        btn_href
      }
      section1 {
        title
        sensorsDataConfig {
          useIcon
          icon {
            publicURL
          }
        }
        compareCompany {
          name
          useIcon
          icon {
            publicURL
          }
        }
        compareContent {
          content
          SensorsData
          compareCompany
        }
      }
      section2 {
        title
        icon {
          publicURL
        }
        desc
        btn_text
        btn_href
        reverse
        img_url {
          publicURL
        }
      }
    }
  }
`
export default googleAnalytics
